import propTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import onClickOutside from "react-onclickoutside";
import { map } from "lodash";
import { Icon } from "../../index";
import "./changeLanguage.scss";

const LOCALES = {
  en: "_enUS",
  "pt-BR": "_ptBR",
  es: "_esALT",
};

class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
    this.languages = ["_ptBR", "_enUS", "_esALT"];
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className={`vg-language ${this.state.isOpen && "vg-active"}`}>
        <button
          type="button"
          className="vg-language-trigger"
          onClick={this.toggleDropdown}
        >
          <Icon iconKey={LOCALES[this.props.currentLocale]} />
          <span>{intl.get(`IDIOMA.${LOCALES[this.props.currentLocale]}`)}</span>
          <Icon iconKey={"arrow"} />
        </button>
        <ul className="vg-language-options">
          {map(this.languages, (item) => {
            return (
              <li key={item}>
                <button onClick={() => this.props.change(item)}>
                  <Icon iconKey={`${item}`} />
                  <span>{intl.get(`IDIOMA.${item}`)}</span>
                  {LOCALES[this.props.currentLocale] === item && (
                    <Icon iconKey={"check"} />
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

ChangeLanguage.propTypes = {
  change: propTypes.func.isRequired,
  currentLocale: propTypes.string.isRequired,
};

export default onClickOutside(ChangeLanguage);
