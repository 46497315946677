import { combineReducers } from "redux";
import { forEach } from "lodash";

const req = require.context(".", false, /\.js$/);
const reducers = {};

forEach(req.keys(), (key) => {
  const filename = key.match(/[a-zA-Z]+.js$/)[0];
  const reducerName = filename.split(".")[0];
  const exportFn = req(key).default;

  if (exportFn) {
    reducers[reducerName] = exportFn;
  }
});

export default combineReducers(reducers);
