import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Cookie from "js-cookie";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import shortid from "shortid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Painel";

import {
  Footer,
  Header,
  Link,
  Help,
  Routers,
  ModalAboutCredits,
} from "../../index";

// locale data
const LOCALES = {
  _enUS: "en",
  _ptBR: "pt-BR",
  _esALT: "es",
};

const mapStateToProps = (state) => ({
  isCredits: !!(
    state.Painel.painel.data &&
    state.Painel.painel.data.cliente &&
    state.Painel.painel.data.cliente.modelo_creditos_vagas
  ),
  isPunctual: !!(
    state.Painel.painel.data &&
    state.Painel.painel.data.cliente &&
    state.Painel.painel.data.cliente.modelo_pontual
  ),
  relationshipManager:
    (state.Painel.painel.data &&
      state.Painel.painel.data.cliente &&
      state.Painel.painel.data.cliente.responsavel_conta) ||
    undefined,
});

class App extends Component {
  constructor(props) {
    super(props);

    Cookie.set(
      "IntegracaoLoginIdioma",
      Cookie.get("IntegracaoLoginIdioma", {
        domain: process.env.REACT_APP_DOMAIN_COOKIE,
      }) || "_ptBR",
      {
        domain: process.env.REACT_APP_DOMAIN_COOKIE,
      }
    );

    this.state = {
      language: Cookie.get("IntegracaoLoginIdioma"),
      currentLocale: Cookie.get("IntegracaoLoginIdioma"),
      checkCandidate: false,
      isVisitor: !!(
        // Só convidado
        (
          (Cookie.get("compartilhamento_token") &&
            !Cookie.get("access_token")) ||
          // Logado, acessando URL de convidado
          (Cookie.get("access_token") &&
            window.location.pathname.match(
              /convidado\/assiste\/video-entrevista/g
            )) ||
          // Candidato para gravação de videoentrevista
          (!Cookie.get("access_token") &&
            !Cookie.get("compartilhamento_token") &&
            window.location.pathname.match(/candidato\/video-entrevista/g)) ||
          window.location.pathname.match(/candidato\/grava\/video-entrevista/g)
        )
      ),
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.themeCandidate = this.themeCandidate.bind(this);
    this.state.currentLocale = LOCALES[this.state.language];

    intl.init({
      currentLocale: this.state.currentLocale,
      locales: {
        en: require("../../../locales/en.json"),
        es: require("../../../locales/es.json"),
        "pt-BR": require("../../../locales/pt-BR.json"),
      },
      fallbackLocale: "pt-BR",
    });

    moment.locale(this.state.currentLocale);
  }

  changeLanguage(value) {
    this.setState(
      {
        language: value,
        currentLocale: LOCALES[value],
      },
      () => {
        Cookie.set("IntegracaoLoginIdioma", value, {
          domain: process.env.REACT_APP_DOMAIN_COOKIE,
        });
        intl.init({
          currentLocale: LOCALES[value],
          locales: {
            [LOCALES[
              value
            ]]: require(`../../../locales/${LOCALES[value]}.json`),
          },
        });
        window.location.reload();
      }
    );
  }

  themeCandidate() {
    return window.location.pathname.match(/candidato\/video-entrevista/g) ||
      window.location.pathname.match(/candidato\/grava\/video-entrevista/g)
      ? true
      : false;
  }

  componentDidMount() {
    const { location } = this.props;
    const bodyElem = document.getElementsByTagName("body")[0];

    if (location) {
      this.setState(
        {
          checkCandidate: this.themeCandidate(),
        },
        () => {
          this.state.checkCandidate
            ? bodyElem.classList.add("vg-candidate-theme")
            : bodyElem.classList.remove("vg-candidate-theme");
        }
      );
    }

    !this.state.isVisitor && receive("painel", "painel");
  }

  render() {
    const { location, isCredits, isPunctual, relationshipManager } = this.props;
    const { checkCandidate, isVisitor } = this.state;

    return (
      <main
        className={`vg-main ${this.themeCandidate() && "vg-main-candidate"}`}
      >
        <Header
          type="painel"
          path="painel"
          check={this.themeCandidate()}
          visitor={isVisitor}
        />
        <TransitionGroup>
          <CSSTransition
            key={location ? location.key : shortid.generate()}
            classNames="fade-translate"
            timeout={300}
          >
            <div className="vg-fix-transition">
              <Routers />
              {!isVisitor && (
                <Footer
                  currentLocale={this.state.currentLocale}
                  changeLanguage={this.changeLanguage}
                  check={checkCandidate}
                />
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
        />
        {!isVisitor && (
          <Help
            isCredits={isCredits}
            relationshipManager={relationshipManager}
          />
        )}
        {isCredits && <ModalAboutCredits isPunctual={isPunctual} />}
      </main>
    );
  }
}

App.propTypes = {
  location: propTypes.object,
  themeCandidate: propTypes.func,
  isCredits: propTypes.bool.isRequired,
  isPunctual: propTypes.bool.isRequired,
  relationshipManager: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(App);
