import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { H2, P } from "@vagastec/epartner-ui";
import intl from "react-intl-universal";
import { SideBar, SupportInfo, Icon } from "../../index";
import "./Help.scss";
import { load as loadHelp, toggleHelp } from "../../../store/modules/Help";

const mapStateToProps = (state) => ({
  isOpen: state.Help.isOpen,
});

class Help extends Component {
  render() {
    const { relationshipManager, isOpen, toggleHelp } = this.props;

    return (
      <SideBar show={isOpen} onClose={toggleHelp}>
        <SupportInfo relationshipManager={relationshipManager} />
        <div className="vg-help-wrapper">
          <div className="vg-help-body">
            <H2>{intl.get("HOME.MODAL.AJUDA.TREINAMENTO")}</H2>
            <P>{intl.get("HOME.MODAL.AJUDA.TEXTO_AGENDE")}</P>
            <P className="vg-schedule">
              <Icon iconKey="schedule" />
              <a
                href="http://materiais.vagas.com.br/treinamentos-online"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.get("HOME.MODAL.AJUDA.BOTAO_AGENDE")}
              </a>
            </P>
          </div>
          <div className="vg-help-link">
            <a
              href="https://ajuda-forbusiness.vagas.com.br"
              className="vg-btn-success"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.get("HOME.MODAL.AJUDA.BOTAO_SUPORTE")}
            </a>
          </div>
        </div>
      </SideBar>
    );
  }
}

Help.propTypes = {
  isOpen: PropTypes.bool,
  loadHelp: PropTypes.func.isRequired,
  toggleHelp: PropTypes.func.isRequired,
  relationshipManager: PropTypes.any,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ loadHelp, toggleHelp }, dispatch)
)(Help);
