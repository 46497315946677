export function getCookie(key) {
  let cookie = document.cookie
    ?.split("; ")
    ?.find((v) => v.startsWith(key))
    ?.split("=")[1];

  if (process.env.NODE_ENV === "development") {
    return cookie;
  }

  return decodeURI(cookie);
}

export function setCookie(
  key,
  value,
  expirationDays = 1,
  domain = process.env.REACT_APP_DOMAIN_COOKIE
) {
  const date = new Date();
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + date.toUTCString();
  return (document.cookie = `${key}=${value};${expires};path=/;domain=${domain};SameSite=Lax`);
}
