import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import moment from "moment";
import { SelectiveProcessList, Intro, FastLink, Graphs } from "../../index";
import SearchBar from "../../molecules/SearchBar";
import "./HomePage.scss";
import { connect } from "react-redux";
import { object } from "prop-types";
import { addVacancyListPageDataLayer } from "../../../utils/dataLayerPush";
import { useBeamer } from "../../../beamer/beamer.hook";
import { useZoho } from "../../../zoho/zoho.hook";

function HomePage({ panel }) {
  const [acessBar, setAcessBar] = useState("");
  const [zohoHasLoaded, setZohoHasLoaded] = useState(false);
  const [hasDigitalAdmission, setHasDigitalAdmission] = useState(false);

  useBeamer(panel);
  useZoho(panel, setZohoHasLoaded);
  useEffect(() => {
    if (panel && panel.cliente && panel.funcionario && zohoHasLoaded) {
      addVacancyListPageDataLayer({
        companyId: panel.cliente.id || "valor-indisponivel",
        companyName: panel.cliente.nome || "valor-indisponivel",
        userId: panel.funcionario.id || "valor-indisponivel",
      });
      setAcessBar(
        panel.funcionario.permissoes.dir_acesso_banco_curriculos == 2
      );
    }
    if (panel?.feature_flags?.includes("admissao_digital"))
      setHasDigitalAdmission(true);
  }, [panel, zohoHasLoaded]);

  return (
    <div className="vg-home-page">
      <Intro type="painel" path="painel" />
      {!!(
        panel &&
        panel?.feature_flags?.includes("pesquisa-simplificada") &&
        acessBar
      ) && <SearchBar />}
      <div className="spacing" />
      <div className="vg-container">
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            <FastLink
              type="painel"
              path="painel"
              admissaoDigital={hasDigitalAdmission}
            />
          </div>
        </section>
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            {panel ? <Graphs /> : null}
          </div>
        </section>
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            <SelectiveProcessList
              panel={panel}
              type="home"
              path={`home?apenas_do_funcionario=true&data_criacao_depois_de=${moment()
                .subtract(4, "month")
                .format("YYYY-MM-DD")}`}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  panel: state.Painel.painel.data,
});

HomePage.propTypes = {
  panel: object.isRequired,
  check: propTypes.any,
};

export default connect(mapStateToProps)(HomePage);
