import "./SupportInfo.scss";

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";
import intl from "react-intl-universal";
import { H1, H3, P } from "@vagastec/epartner-ui";

const SupportInfo = ({ relationshipManager }) => {

  return (
    <section className="vg-support-info">
      <H1>{intl.get("HOME.MODAL.AJUDA.TITULO_SUPORTE")}</H1>
      <P>{intl.get("HOME.MODAL.AJUDA.SUBTITULO_SUPORTE")}</P>

      {relationshipManager && (
        <Fragment>
          <H3>
            {intl.get("HOME.MODAL.AJUDA.TITULO_CONTATO_GERENTE_RELACIONAMENTO")}
          </H3>
          <P>
            <Icon iconKey="id-card" />
            <span> {relationshipManager.nome}</span>
          </P>
          <P>
            <Icon iconKey="envelope" />
            <span> {relationshipManager.email}</span>
          </P>
        </Fragment>
      )}

      <H3>{intl.get("HOME.MODAL.AJUDA.TITULO_CONTATO_SUPORTE")}</H3>
      <P>
        <Icon iconKey="envelope" />
        <span>suporte.empresas@vagas.com</span>
      </P>
      <P>
        <span>{intl.get("HOME.MODAL.AJUDA.MSG_SUPORTE")}</span>
      </P>
    </section>
  );
};

SupportInfo.propTypes = {
  relationshipManager: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

export default SupportInfo;
