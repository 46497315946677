import React from "react";
import ReactDOM from "react-dom";
import { Route, Router } from "react-router-dom";
import { ThemeProvider, defaultTheme } from "@vagastec/epartner-ui";
import { Provider } from "react-redux";
// import { datadogRum } from "@datadog/browser-rum";
// import { datadogLogs } from "@datadog/browser-logs";
import { App, IconsList } from "../src/components/index";
import ApiClient from "../src/utils/ApiClient";
import configureStore from "../src/store/configureStore";
import history from "../src/utils/history";
import "../src/styles/application.scss";
import "../src/loader/loader";

// if (process.env.NODE_ENV === "production") {
//   datadogRum.init({
//     applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
//     clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     service: "zeus",
//     env: "main",
//     // Specify a version number to identify the deployed version of your application in Datadog
//     version: "1.0.10",
//     sessionSampleRate: 10,
//     sessionReplaySampleRate: 0,
//     trackSessionAcrossSubdomains: true,
//     useSecureSessionCookie: true,
//     useCrossSiteSessionCookie: true,
//   });

//   datadogLogs.init({
//     clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     forwardErrorsToLogs: true,
//     sessionSampleRate: 10,
//     trackSessionAcrossSubdomains: true,
//     useSecureSessionCookie: true,
//     useCrossSiteSessionCookie: true,
//   });
// }

const client = new ApiClient();
const ReduxStore = configureStore(window.REDUX_INITIAL_DATA, client);
const rootElement = document.getElementById("root");
const iconsListElement = document.getElementById("iconsList");

rootElement &&
  ReactDOM.render(
    <Provider store={ReduxStore}>
      <ThemeProvider theme={defaultTheme}>
        <Router basename={process.env.PUBLIC_URL} history={history}>
          <Route path="/" component={App} />
        </Router>
      </ThemeProvider>
    </Provider>,
    rootElement
  );

iconsListElement &&
  ReactDOM.render(
    <Router history={history}>
      <Route path="/icones" component={IconsList} />
    </Router>,
    iconsListElement
  );

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept();
}
