import "./DashboardButton.scss";

import React, { useState } from "react";
import { array } from "prop-types";
import { Link } from "../../index";
import intl from "react-intl-universal";
import onClickOutside from "react-onclickoutside";

function DashboardButton({ featureFlags }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen(e) {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }

  DashboardButton.handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={
        isOpen ? "vg-user-active vg-dashboard-button" : "vg-dashboard-button"
      }
    >
      <a
        href="#"
        className="vg-user-link"
        onClick={toggleIsOpen}
        aria-label={intl.get("NAVEGACAO.MENU")}
      >
        <box-icon name="grid" type="solid" color="white" aria-hidden />
      </a>
      <ul className="vg-user-menu">
        <h3>{intl.get("NAVEGACAO.RECURSOS_ADICIONAIS")}</h3>
        <div className="row">
          {featureFlags.includes("link-entrevistas-online") && (
            <li>
              <Link
                to="/entrevistas-online"
                target="_self"
                id="entrevistas-online"
              >
                <span className="round-link">
                  <box-icon type="solid" name="videos"></box-icon>
                  <span className="round-link--badge-new">
                    {intl.get("NAVEGACAO.NOVO")}
                  </span>
                </span>
                <span className="label">
                  {intl.get("NAVEGACAO.ENTREVISTAS_ONLINE")}
                </span>
              </Link>
            </li>
          )}
          {featureFlags.includes("link-marketing-place") && (
            <li>
              <Link to="/marketplace" target="_self">
                <span className="round-link">
                  <box-icon type="solid" name="widget"></box-icon>
                  <span className="round-link--badge-new">Novo</span>
                </span>
                <span className="label">
                  {intl.get("NAVEGACAO.MARKETPLACE")}
                </span>
              </Link>
            </li>
          )}
        </div>
      </ul>
    </div>
  );
}

DashboardButton.propTypes = {
  featureFlags: array,
};

const clickOutsideConfig = {
  handleClickOutside: () => DashboardButton.handleClickOutside,
};

export default onClickOutside(DashboardButton, clickOutsideConfig);
