import React from "react";
import intl from "react-intl-universal";
import {
  Button,
  Icon,
  Navigation,
  UserNavigation,
  DashboardButton,
} from "../../index";
import { toggleHelp } from "../../../store/modules/Help";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import Cookie from "js-cookie";
import delve from "dlv";

const HeaderCheck = ({ accesses, check, toggleHelp, location }) => {
  const isCredits = !!(
    accesses.cliente && accesses.cliente.modelo_creditos_vagas
  );

  const dashboardButton =
    accesses.feature_flags &&
    (accesses.feature_flags.includes("link-marketing-place") ||
      accesses.feature_flags.includes("link-entrevistas-online"));

  return check ||
    (Cookie.get("compartilhamento_token") &&
      Cookie.get("access_token") === null) ? null : (
    <div className={`vg-header-wrapper dash-${dashboardButton}`}>
      <Navigation
        accesses={accesses.acessos.itens_menu}
        clientId={delve(accesses, "cliente.id", 0)}
        userId={accesses.funcionario.id}
        location={location}
      />
      <div className="vg-header-right-section list-icons-header">
        <Button
          onClick={toggleHelp}
          className="vg-help"
          aria-label={intl.get("NAVEGACAO.AJUDA")}
        >
          <Icon iconKey="help" aria-hidden />
        </Button>
        <UserNavigation
          navigation={accesses.acessos.itens_menu_usuario}
          username={accesses.funcionario.nome}
          isCredits={isCredits}
        />
        {dashboardButton && (
          <DashboardButton featureFlags={accesses.feature_flags} />
        )}
      </div>
    </div>
  );
};

HeaderCheck.defaultProps = {
  accesses: {
    funcionario: {},
    cliente: {},
    acessos: {},
    feature_flags: [],
  },
};

HeaderCheck.propTypes = {
  accesses: propTypes.object.isRequired,
  check: propTypes.any,
  toggleHelp: propTypes.func,
  location: propTypes.object,
};

export default withRouter(connect(null, { toggleHelp })(HeaderCheck));
