import { useEffect } from "react";

export const useZoho = (permissions, setHasLoaded) => {
  useEffect(() => {
    if (!permissions) return;
    let maxTries = 5;
    const timer = setInterval(() => {
      maxTries--;
      if (document.getElementsByClassName("zsiq_floatmain") || maxTries <= 0) {
        setHasLoaded(true);
        clearInterval(timer);
      }
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!permissions) return;

    const { cliente, funcionario } = permissions;
    const grName =
      cliente?.responsavel_conta?.nome ?? "Informação não cadastrada";

    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: process.env.REACT_APP_ZOHO_WIDGET_CODE,
      values: {},
      ready: function () {
        window.$zoho.salesiq.visitor.name(funcionario?.nome); // nome_func
        window.$zoho.salesiq.visitor.email(funcionario?.email); // email_func
        // window.$zoho.salesiq.visitor.contactnumber("+55 11 99626-2005"); // Fone_func
        window.$zoho.salesiq.visitor.info({
          Cliente: cliente?.nome,
          Ident_Cli: cliente?.identidade, // ident_cli
          ID_CRM: cliente?.crm_id, // IdContaCRM_cli
          Plano: cliente?.plano?.descricao, // Descr_tipoPlano
          Ident_Func: funcionario?.identidade, // ident_func
          Admin: funcionario?.admin, // admin_func
          Nome_GR: grName, // Nome_usuman
          // Cargo_depto: "Especialista de Selecao", // cargodepto_func
        });
      },
    };

    const d = document;

    const s = d.createElement("script");
    s.type = "text/javascript";
    s.id = "zsiqscript";
    s.src = "https://salesiq.zoho.com/widget";
    s.defer = true;

    const t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  }, [permissions]);
};
