import propTypes from "prop-types";
import React, { Component } from "react";
import { Link, Avatar } from "../../index";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Painel";
import { returnTotal } from "../../../store/modules/Vacancies";
import "./Intro.scss";

const mapStateToProps = (state) => ({
  intro: state.Painel.painel.data,
  returnState: state.Vacancies,
});

class Intro extends Component {
  componentDidMount() {
    const { receive, path, type } = this.props;
    // Carrega as permissões do painel
    receive(type, path);
    // Retorna o state atual de vagas
    returnTotal();
  }

  render() {
    const { intro, returnState } = this.props; // eslint-disable-line no-shadow
    const total = returnState.home.vacancies.length;

    return (
      <div className="vg-intro">
        <div className="vg-container-fluid">
          <Avatar alt={intro.funcionario.nome} />
          <h1>
            {intl.get("INTRO.SAUDACOES", { name: intro.funcionario.nome })}
          </h1>
          <p>
            {intl.getHTML("INTRO.VAGAS", {
              report: total,
              company: intro.cliente.nome,
            })}
            <Link to="/vagas" data-testid="link-acompanhar">
              {intl.get("INTRO.ACOMPANHAR")}
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

Intro.defaultProps = {
  intro: { funcionario: {}, cliente: {} },
};

Intro.propTypes = {
  path: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  intro: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
  returnTotal: propTypes.func.isRequired,
  returnState: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, returnTotal }, dispatch)
)(Intro);
