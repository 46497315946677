import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { styled, resetButton, device } from "@vagastec/epartner-ui";
import { object, number } from "prop-types";
import Cookie from "js-cookie";
import { headerDatalayer } from "../../../utils/dataLayerPush";

const Chat = styled.button`
  ${resetButton};
  display: none;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};

  ${device.desktop`
    display: flex;
  `}

  span {
    font-size: 1.5em;
  }

  box-icon {
    fill: currentColor;
    margin-left: 2px;
  }
`;

const ChatButton = ({ funcionario, cliente, userId }) => {
  const info = {
    participante: {
      equipe: {
        nome: cliente.nome,
        avatar: "null",
        sistema_equipe_id: cliente.id,
      },
      perfil_sistema: {
        perfil: "Recrutador",
        sistema: "e-parthenon",
        dominio: "www.vagas.com",
      },
      sistema_usuario_id: funcionario.id,
      nome: funcionario.nome,
      avatar: "null",
    },
  };
  const [loader, setLoader] = useState(false);

  const handleClick = () => {
    setLoader(true);
    headerDatalayer("interaction", "chat", userId);
    fetch(process.env.REACT_APP_CHAT_FETCH_URL, {
      method: "post",
      credentials: "include",
      body: JSON.stringify(info),
      headers: {
        Authorization: `Bearer ${Cookie.get("access_token")}`,
        "Content-Type": "application/json",
        "Accept-Language": "pt-BR",
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(
        () => (window.location.href = process.env.REACT_APP_CHAT_REDIRECT_URL)
      )
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <Chat onClick={handleClick} disabled={loader}>
      {loader ? (
        <box-icon name="loader-circle" animation="spin" />
      ) : (
        <Fragment>
          <span>Chat</span>
          <box-icon type="solid" name="chat" size="18px" />
        </Fragment>
      )}
    </Chat>
  );
};

ChatButton.propTypes = {
  funcionario: object.isRequired,
  cliente: object.isRequired,
  userId: number.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.Painel.painel.data.funcionario.id,
});

export default connect(mapStateToProps)(ChatButton);
